<template>
    <v-autocomplete :items="items" item-text="name" item-value="id" @input="handleInput" v-model="room"
        :loading="loading" label="Select Room" solo />
</template>

<script>
export default {
    prop: ['value'],
    data() {
        return {
            items: [],
            room: this.value || '',
            loading: false,
        }
    },
    mounted() {
        this.loadAdministrationRooms();
    },
    methods: {
        handleInput() {
            this.$emit('input', this.room);
        },
        loadAdministrationRooms() {
            this.loading = true;
            this.$api.get("/rooms/").then((res) => {
                this.items = res.data.results;
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        },
    },
}
</script>

<style>

</style>