<template>
  <v-card class="pa-0">
    <!-- <v-card-title>Room Fee Heads</v-card-title> -->

    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <RoomAutoComplete v-model="room"></RoomAutoComplete>
      </v-col>
    </v-row>

    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>Monthly Fees Heads</v-card-title>
      <v-spacer> </v-spacer>
      <v-dialog max-width="600px" v-model="dialogNewFee">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" class="ma-4" dark v-on="on" outlined
            >Add New Fee</v-btn
          >
        </template>
        <new-fee :room_id="room" @closeDialog="closeDialogNewFee"></new-fee>
      </v-dialog>
    </v-toolbar>
    <DataTableSSR
      :headers="headers"
      api-end-point="/payments/fees"
      :queryParams="{
        room: room,
      }"
      :searchable="true"
      :instantLoad="true"
    >
    </DataTableSSR>
  </v-card>
</template>

<script>
import DataTableSSR from "@/components/global/DataTableSSR.vue";
import NewFee from "@/components/fees/NewFee.vue";
import RoomAutoComplete from "@/components/RoomAutoComplete.vue";
export default {
  components: { NewFee, DataTableSSR, RoomAutoComplete },
  props: ["room_id", "apiEndPoint"],

  data() {
    return {
      dialogNewFee: false,
      room: "",
      headers: [
        {
          text: "Name",
          value: "fee.name",
        },
        {
          text: "Amount",
          value: "fee.amount",
        },
        {
          text: "Month",
          value: "fee_month",
        },
        {
          text: "Year",
          value: "year",
        },
      ],
    };
  },
  created() {},
  methods: {
    closeDialogNewFee() {
      this.dialogNewFee = false;
    },
  },
};
</script>

<style>
</style>
